import axios from 'axios';
import serviceConfig from "./serviceConfig";
import { getToken } from "./../auth/utils";
import { checkStatusCode } from "./services";

const queryString = require('query-string');
const svConfig = { ...serviceConfig };

const loadDataAll = async (urlPath) => {
  let config = {
    method: svConfig.method_get,
    url: urlPath,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      code,
      result
    };
  }
}

const getCommission = async (id) => {
  let myJson = {
    "id": id
  };

  let config = {
    method: svConfig.method_post,
    url: "/customer/commission/claim",
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: queryString.stringify(myJson)
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      code,
      result
    };
  }
}

const claimAll = async (path, type) => {
  let myJson = {
    "game_camp_type": type
  };

  let config = {
    method: svConfig.method_post,
    url: path,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: queryString.stringify(myJson)
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      code,
      result
    };
  }
}

const loadHistory = async (path, limit = -1, offset = 0) => {
  let myJson = {
    "limit": limit,
    "offset": offset
  };

  let config = {
    method: svConfig.method_post,
    url: path,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: queryString.stringify(myJson)
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      code,
      result
    };
  }
}

const claimInviteAll = async (path) => {
  let config = {
    method: svConfig.method_get,
    url: path,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      code,
      result
    };
  }
}

export {
  loadDataAll,
  getCommission,
  claimAll,
  loadHistory,
  claimInviteAll
};