import { useEffect, useRef, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// @utility
import alertText from "./../../../../../@utility/app/alertText";
import loadImagesFetch from "./../../../../../@utility/app/loadImagesFetch";

// Services
import { checkExpireToken, refreshToken } from "./../../../../../@core/auth/jwt/jwtService";
import { loadHomeData } from "./../../../../../@core/services/homeService";

// Auth
import { isUserLoggedIn } from "./../../../../../@core/auth/utils";

// Redux
import { setHomeData } from "./../../../../../@core/redux/actions/home/index";
import { setLoadHome } from "./../../../../../@core/redux/actions/app/processing";

// Component
import ContentHome from "./component/Content";
import SnackbarSet from "./../../components/SnackbarSet";
import NotiEvent from "./../../components/NotiEvent";
import SkeletionScreen from "./component/SkeletionScreen";

const HomeApp = ({ agent_setting_menu }) => {
    document.title = process.env.REACT_APP_OG_SITE_NAME+ " - หน้าหลัก";
    
    const childRef = useRef();
    const dispatch = useDispatch();
    const [loadComponent, setLoadComponent] = useState(false);
    const [processing, setProcessing] = useState(false);
    const dataCampAll = useSelector(state => state.reduxGame.itemsCampList);

    useEffect(() => {
        let snackbarJson = {};
        if (isUserLoggedIn()) {
            loadHomeData().then((data) => {
                const statusCode = data.code;
                if (statusCode === 0 || statusCode === 200) {
                    dispatch(setHomeData(data.result));
                }
                else {
                    if (statusCode === 2 || statusCode === 4) {
                        snackbarJson = alertText(statusCode);
                        childRef.current.snackbar(snackbarJson);

                        setTimeout(function () {
                            window.location.href = `/?web=${process.env.REACT_APP_DEFAULT_AGENT_CODE}`;
                        }, 5000);
                    }
                    else {
                        snackbarJson = alertText(statusCode);
                        childRef.current.snackbar(snackbarJson);
                    }
                }
            });

            if (checkExpireToken()) {
                refreshToken();
            }
        }

    }, [isUserLoggedIn(), dispatch]);

    useEffect(() => {
        if (loadComponent) {
            let statusLoad = loadImagesFetch(1);
            if (statusLoad) {
                dispatch(setLoadHome(true));
            }
        }
    }, [dispatch, loadComponent]);

    return (
        <>
            <div id="HomeApp" className="HomeApp">
                {
                    processing ?
                        <ContentHome isUserLoggedIn={isUserLoggedIn()} dataCampAll={dataCampAll} setting_menu={agent_setting_menu} processing={processing} setProcessing={setProcessing} loadComponent={loadComponent} setLoadComponent={setLoadComponent}></ContentHome>
                        :
                        <SkeletionScreen processing={processing} setProcessing={setProcessing} dataCampAll={dataCampAll}></SkeletionScreen>
                }
            </div>

            <SnackbarSet ref={childRef} />
            <NotiEvent></NotiEvent>
        </>
    );
}

export default HomeApp;