import { 
    OPEN_ACTIVITY,
    SET_ACTIVITY_DATA
} from './../../actions/activity/index';

const initialState = {
    tab: "",
    show: false,
    items: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_ACTIVITY:
            return { ...state, ...action.payload.items };
        case SET_ACTIVITY_DATA:
            return { ...state, items: action.payload.items };
        default:
            return state;
    }
}