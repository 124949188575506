const OPEN_ACTIVITY = 'app/OPEN_ACTIVITY';
const SET_ACTIVITY_DATA = 'app/SET_ACTIVITY_DATA';

function openActivity(data) {
    return {
        type: OPEN_ACTIVITY,
        payload: {
            items: data
        }
    }
}

function setActivityData(data) {
    return {
        type: SET_ACTIVITY_DATA,
        payload: {
            items: data
        }
    }
}

export {
    OPEN_ACTIVITY,
    SET_ACTIVITY_DATA,
    openActivity,
    setActivityData
}