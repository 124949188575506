import { useEffect } from 'react';
import Container from '@material-ui/core/Container';

// @utility
import setBodyScroll from "./../../../../../@utility/app/setBodyScroll";

// Component
import ContentGame from './component/Content';
import NotiEvent from "./../../components/NotiEvent";

const GamePlayPopupApp = ({ popupGameData }) => {

    useEffect(() => {
        document.body.style.zoom = 1;
        setBodyScroll(true);
    }, [popupGameData?.show]);

    return (
        <>
            <div className="GamePlayPopupApp">
                <Container maxWidth="xl" className="bgContainer">
                    <ContentGame camp={popupGameData.camp} gamecode={popupGameData.gamecode}></ContentGame>
                </Container>
            </div>

            <NotiEvent></NotiEvent>
        </>
    );
}

export default GamePlayPopupApp;