import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

// @Utility
import currencyFormat from "./../../../../../../@utility/numbers/currencyFormat";
import alertText from "../../../../../../@utility/app/alertText";

// Redux
import { setDataAlert } from "../../../../../../@core/redux/actions/app/alert";
import { setStatusSpinner } from "../../../../../../@core/redux/actions/app/spinner";
import { setHomeData } from "../../../../../../@core/redux/actions/home/index";

// Services
import { clickWheelSpin } from "../../../../../../@core/services/miniGameService";
import { loadHomeData } from "../../../../../../@core/services/homeService";

// CSS
import styles from "./../../../assets/css/Style.module.css";
import buttonStyles from "./../../../assets/css/Button.module.css";
import "./../../../assets/css/SpinGame.css";

const WheelSpinnig = ({ spinDataAll }) => {
    window.scrollTo(0, 0);
    const homeData = useSelector(state => state.reduxHome.result);
    const [wheelData, setWheelData] = useState(null);
    const [wheelStatus, setWheelStatus] = useState(false);
    const dispatch = useDispatch();
    let snackbarJson = {};

    const startSpin = (index, element, result) => {
        setWheelStatus(true);

        setTimeout(() => {
            const wheelElement = document.getElementById("wheel");
            const wheelData = document.querySelectorAll(".wheel div.number");
            let wheelSlice = 360 / wheelData.length;
            let ranEvent = Math.floor(Math.random() * 2);

            let rot = (wheelSlice * index) + (360 * wheelData.length);
            if (ranEvent === 0) {
                rot = rot - (Math.random() * (wheelData.length * 2));
            }
            else {
                rot = rot + (Math.random() * (wheelData.length * 2));
            }

            wheelElement.style.transform = `rotate(-${rot}deg)`;

            setTimeout(() => {
                element.classList.add("animate");
                setTimeout(() => {
                    if (result.jackpot === 0) {
                        if (result.quantity <= 0) {
                            snackbarJson = {
                                statusShow: true,
                                statusAlert: "wheelSpin",
                                titleAlert: "น่าเสียดาย ไม่ได้รับรางวัล",
                                textAlert: "กรุณาลองใหม่ครั้งหน้า"
                            }
                        }
                        else {
                            snackbarJson = {
                                statusShow: true,
                                statusAlert: "wheelSpin",
                                titleAlert: "ยินดีด้วยคุณได้รับ",
                                textAlert: `เครดิตฟรี จำนวน ${result.quantity} บาท`
                            }
                        }
    
                        dispatch(setDataAlert({
                            type: snackbarJson.statusAlert,
                            title: snackbarJson.titleAlert,
                            text: snackbarJson.textAlert,
                            image: result.icon,
                            action: true,
                            redirect: null,
                            show: true,
                            buttonText: "ตกลง"
                        }));
                    }

                    setWheelStatus(false);
                    element.classList.remove("animate");
                    wheelElement.style.transform = `rotate(${0}deg)`;
                }, 1200);

            }, 5000);
        }, 100);
    };

    const requestReward = () => {
        if(wheelStatus === false) {
            if(homeData.promotion_status === 0) {
                if (homeData.wallets.length > 0) {
                    if (homeData.wallets[0].balance >= 50) {
                        dispatch(setStatusSpinner({ status: true, textProcess: "กำลังตรวจสอบข้อมูล กรุณารอสักครู่" }));
                        clickWheelSpin().then((data) => {
                            dispatch(setStatusSpinner({ status: false, textProcess: null }));
                            const statusCode = data.code;
                            if (data.code === 0 || data.code === 200) {
                                const result = data.result;
                                reloadBalance();
        
                                if (result !== null) {
                                    setTimeout(() => {
                                        let index = spinDataAll.wheel.findIndex(item => item.code === result.code);
                                        let element = document.getElementById("needle");
                                        element.classList.remove("animate");
        
                                        startSpin(index, element, result);
                                    }, 1000);
                                }
                            }
                            else {
                                alertResponse(statusCode);
                            }
                        });
                    }
                    else {
                        openBox({
                            statusShow: true,
                            statusAlert: "warning",
                            titleAlert: "แจ้งเตือน",
                            textAlert: "คุณมีเเต้มไม่เพียงพอ"
                        });
                    }
                }
                else {
                    openBox({
                        statusShow: true,
                        statusAlert: "warning",
                        titleAlert: "แจ้งเตือน",
                        textAlert: "ไม่พบข้อมูลกระเป๋า กรุณาติดต่อผู้ดูแลระบบ"
                    });
                }
            }
            else {
                openBox({
                    statusShow: true,
                    statusAlert: "warning",
                    titleAlert: "แจ้งเตือน",
                    textAlert: "ไม่สามารถหมุนกงล้อได้ เนื่องจากติดเงื่อนไขรับโปร"
                });
            }
        } 
    };

    const reloadBalance = () => {
        loadHomeData().then((data) => {
            const statusCode = data.code;
            if (statusCode === 0 || statusCode === 200) {
                dispatch(setHomeData(data.result));
            }
        });
    };

    const alertResponse = (statusCode) => {
        if (statusCode === 2 || statusCode === 4) {
            snackbarJson = alertText(statusCode);
            openBox(snackbarJson);

            setTimeout(function () {
                window.location.href = `/?web=${process.env.REACT_APP_DEFAULT_AGENT_CODE}`;
            }, 5000);
        }
        else {
            snackbarJson = alertText(statusCode);
            openBox(snackbarJson);
        }
    };

    const openBox = (snackbarJson) => {
        dispatch(setDataAlert({
            type: snackbarJson.statusAlert,
            title: snackbarJson.titleAlert,
            text: snackbarJson.textAlert,
            action: true,
            redirect: null,
            show: true,
            buttonText: "ตกลง"
        }));
    };

    useEffect(() => {
        if (spinDataAll !== null) {
            let getArr = [];
            let setColor = ["#FF0000", "#0038FF", "#603ACD", "#AD00BC", "#19B600"];
            let jackpotColor = "#000000";
            let countLoop = 0;

            spinDataAll.wheel.forEach(element => {
                let wheelJson = {};
                if (element.jackpot) {
                    wheelJson = { ...wheelJson, ...element, color: jackpotColor }
                }
                else {
                    if (countLoop === 5) {
                        countLoop = 0;
                    }

                    wheelJson = { ...wheelJson, ...element, color: setColor[countLoop] }
                    ++countLoop;
                }

                getArr = [...getArr, wheelJson];
            });

            setWheelData(getArr)
        }
    }, [spinDataAll]);

    return (
        spinDataAll !== null && (
            <div id="spinGame">
                <div className="mainbox">
                    <div id="needle" className="needle">
                        <img className="needleImg" src={spinDataAll.asset.needle} />
                    </div>

                    <img className="spinOuterImg" src="/images/spin/outer-edge.webp" />

                    <div className="wheelPosition">
                        {
                            wheelStatus ?
                                <div id="wheel" className="wheel">
                                    {
                                        wheelData !== null && (
                                            wheelData.map((item, index) => (
                                                <div key={item.code} className="number" style={{ "--i": `${index + 1}`, "--clr": `${item.color}` }}>
                                                    <div className="wheelReward">
                                                        <div className="wheelRewardPaddingTop">
                                                            <div className="wheelRewardText">
                                                                {
                                                                    item.name
                                                                }
                                                            </div>

                                                            <div className="wheelRewardImgMarginTop">
                                                                <img className="wheelRewardImg" src={item.icon} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        )
                                    }
                                </div>
                                :
                                <div className="wheel-wait">
                                    {
                                        wheelData !== null && (
                                            wheelData.map((item, index) => (
                                                <div key={item.code} className="number" style={{ "--i": `${index + 1.46}`, "--clr": `${item.color}` }}>
                                                    <div className="wheelReward">
                                                        <div className="wheelRewardPaddingTop">
                                                            <div className="wheelRewardText">
                                                                {
                                                                    item.name
                                                                }
                                                            </div>

                                                            <div className="wheelRewardImgMarginTop">
                                                                <img className="wheelRewardImg" src={item.icon} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        )
                                    }
                                </div>
                        }
                    </div>

                    <div className="spin">
                        <img className="spinImg" src={spinDataAll.asset.spin} />
                    </div>
                </div>

                {
                    homeData !== null && (
                        <div className={styles.boxMarginFormTop}>
                            <div className="center">
                                <div className="spinPoint">
                                    คุณมี <span className="spinPointNumber">{currencyFormat(homeData.wallets[0].balance, 2)}</span> แต้ม
                                </div>
                                <div className={styles.boxMarginFormTop}>
                                    <div className={buttonStyles.btnSpinStart} onClick={requestReward}>
                                        เริ่มหมุน
                                    </div>
                                </div>
                                <div className={styles.boxMarginFormTop}>
                                    <div className="spinPointText">
                                        ใช้ {spinDataAll.info.point} เเต้ม ในการหมุนกงล้อ 1 ครั้ง
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        )
    );
}

export default WheelSpinnig;