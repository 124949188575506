import axios from 'axios';
import serviceConfig from "./serviceConfig";
import { getToken } from "./../auth/utils";
import { checkStatusCode } from "./services";

const queryString = require('query-string');
const svConfig = { ...serviceConfig };

const loadWithdrawFirst = async () => {
    let config = {
        method: svConfig.method_get,
        url: '/customer/payment/withdraw',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: {}
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const confirmWithdraw = async (data) => {
    const {
        amount,
        bankId
    } = data;

    let myJson = {
        "amount": amount,
        "bankId": bankId
    };

    let config = {
        method: svConfig.method_post,
        url: `/customer/payment/withdraw/automatic`,
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: queryString.stringify(myJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const checkWithdraw = async (depositId) => {
    let myJson = {
        "id": depositId
    };

    let config = {
        method: svConfig.method_post,
        url: '/customer/payment/withdraw/check',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: queryString.stringify(myJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

export {
    loadWithdrawFirst,
    confirmWithdraw,
    checkWithdraw
};