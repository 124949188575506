import axios from 'axios';
import serviceConfig from "./serviceConfig";
import { getToken } from "./../auth/utils";
import { checkStatusCode } from "./services";

const queryString = require('query-string');
const svConfig = { ...serviceConfig };

const loadDepositFirst = async () => {
    let config = {
        method: svConfig.method_get,
        url: '/customer/payment/deposit/home',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: {}
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const confirmDeposit = async (data) => {
    const {
        amount,
        bankId,
        typePage
    } = data;

    let myJson = {};
    let methodType = 4;

    if(typePage === "qrcode") {
        methodType = 5;
    }
    else if(typePage === "truemoney") {
        methodType = 6;
    }
    else if(typePage === "bank") {
        methodType = 4;
    }
    else {
        methodType = 0;
    }

    let urlpath = "";
    if(methodType === 0 || methodType === 6) {
        urlpath = '/customer/payment/deposit/money';
        myJson = {
            "amount": amount,
            "bank_id": bankId,
            "method": methodType
        };
    }
    else {
        urlpath = '/customer/payment/deposit/automatic';
        myJson = {
            "amount": amount,
            "bankId": bankId,
            "method": methodType
        };
    }

    let config = {
        method: svConfig.method_post,
        url: urlpath,
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: queryString.stringify(myJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const confirmStep = async (data) => {
    const {
        td_id,
        promotion_status,
        promotion_condition_id
    } = data;

    let myJson = {
        "td_id": td_id,
        "promotion_status": promotion_status,
        "promotion_condition_id": promotion_condition_id
    };

    let config = {
        method: svConfig.method_post,
        url: '/customer/payment/deposit/confirm',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: queryString.stringify(myJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const confirmPaySlip = async (data) => {
    let config = {
        method: svConfig.method_post,
        url: '/customer/payment/deposit/slip',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: data
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const checkDeposit = async (depositId) => {
    let myJson = {
        "id": depositId
    };

    let config = {
        method: svConfig.method_post,
        url: '/customer/payment/deposit/check',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: queryString.stringify(myJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const cancenDeposit = async () => {
    let config = {
        method: svConfig.method_post,
        url: '/customer/payment/deposit/cancel',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: {}
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const getBankAgent = async (bankId) => {
    let myJson = {
        "bankId": bankId
    };

    let config = {
        method: svConfig.method_post,
        url: '/customer/payment/deposit/bank/agent',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: queryString.stringify(myJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

const getBankCustomer = async (bankId) => {
    let myJson = {
        "bankId": bankId
    };

    let config = {
        method: svConfig.method_post,
        url: '/customer/payment/deposit/bank/customer',
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: queryString.stringify(myJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

export {
    loadDepositFirst,
    confirmDeposit,
    confirmStep,
    confirmPaySlip,
    checkDeposit,
    cancenDeposit,
    getBankAgent,
    getBankCustomer
};