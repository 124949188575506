const SET_WITHDRAW_DATA = 'app/SET_WITHDRAW_DATA';

function setWithdrawData(data) {
    return {
        type: SET_WITHDRAW_DATA,
        payload: {
            items: data
        }
    }
}

export {
    SET_WITHDRAW_DATA,
    setWithdrawData
}