import { useState, useEffect, useRef } from 'react';
import Container from '@material-ui/core/Container';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TagManager from 'react-gtm-module';

// @Utility
import { setStorage, getStorage, removeStorage } from "./../../@utility/app/storage";

// auth
import { isUserLoggedIn } from "./../../@core/auth/utils";

// Hooks
import useParameterUrl from "./../../@utility/hooks/useParameterUrl";

// Services
import { loadHomeData } from "../../@core/services/homeService";
import { loadCampAll } from "./../../@core/services/gameService";

// Redux
import { setHomeData } from "./../../@core/redux/actions/home/index";
import { 
    setLoadHome,
    setLoadImages
} from "./../../@core/redux/actions/app/processing";
import { setDataCMS } from "./../../@core/redux/actions/app/cms";
import { setCampAll } from './../../@core/redux/actions/game/index';
import { setGameHitAll } from "./../../@core/redux/actions/game/index";
import { loadRank } from './../../@core/redux/actions/rank';
import { loadPromotionAll } from './../../@core/redux/actions/promotion';
import { setHomeMenuType } from "./../../@core/redux/actions/home/index";

// Routes
import VerticalRoutes from "./vertical/VerticalRoutes";

// Component
import Navbar from "./vertical/components/Navbar/index";
import FooterMenu from "./vertical/components/Footer/index";
import BrannerSlide from "./vertical/components/BrannerSlide";
import TextAnimate from "./vertical/components/TextAnimate";
import NavbarMenu from "./vertical/components/NavbarMenu";
import SnackbarApp from "./vertical/components/Alert/Snackbar";
import SnackbarSet from "./vertical/components/SnackbarSet";
import DialogAction from "./vertical/components/DialogAction";
import LoadSpinner from "./vertical/components/LoadSpinner";
import ConditionPopup from "./vertical/components/ConditionPopup";
import Contact from "./vertical/components/Contact";
import ConfirmApp from "./vertical/components/Confirm";
import AlertApp from "./vertical/components/Alert/index";
import FooterHome from "./vertical/components/Footer";
import ListContact from "./vertical/components/ListContact";
import LoadSkeletonScreen from "./vertical/components/LoadSkeletonScreen";
import AffiateApp from "./vertical/pages/affiate/index";
import InviteApp from "./vertical/pages/invite/index";
import GamePlayPopupApp from "./vertical/pages/game-play-popup/index";
import SpinWheel from "./vertical/components/MiniGame/SpinWheel";

const Layouts = (action) => {
    const [loadRoutes, setLoadRoutes] = useState(false);
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const dialogData = useSelector(state => state.reduxAppDialog.dialogData);
    const conditionData = useSelector(state => state.reduxAppCondition.conditionData);
    const contactStatus = useSelector(state => state.reduxAppContact.statusShow);
    const reduxSpinner = useSelector(state => state.reduxAppSpinner.data);
    const confirmData = useSelector(state => state.reduxAppConfirm);
    const alertData = useSelector(state => state.reduxAppAlert);
    const imagesProcessing = useSelector(state => state.reduxAppProcessing.images);
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);
    const childRef = useRef();
    const statusShow = useSelector(state => state.reduxAppSnackbar.snackbarData.statusShow);
    const affiliate = useSelector(state => state.reduxAffiliate.affiliate);
    const linkFriend = useSelector(state => state.reduxAffiliate.linkFriend);
    const popupGameData = useSelector(state => state.reduxGame.popupGameData);
    const reduxAppWheel = useSelector(state => state.reduxAppWheel.show);
    const dispatch = useDispatch();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const [pathName, setPathName] = useState("/");
    const [processing, setProcessing] = useState(false);
    const [processingNavbar, setProcessingNavbar] = useState(false);
    
    const parsed = useParameterUrl();
    const { iframe_load } = parsed;
    const [iframeStatus, setIframeStatus] = useState(false);

    const renderAlertBar = () => {
        if (statusShow === false) return null;

        return (
            <SnackbarApp></SnackbarApp>
        );
    };

    const setLoadDataCms = (dataCms) => {
        dispatch(setDataCMS(dataCms));
        setStorage("data_cms", JSON.stringify(dataCms));
        document.getElementsByTagName("body")[0].style.background = dataCms["bg-foter"];
        document.documentElement.style.setProperty("--bg-card", dataCms["bg-card"]);
        document.documentElement.style.setProperty("--bg-foter", dataCms["bg-foter"]);
        document.documentElement.style.setProperty("--bg-main", dataCms["bg-main"]);
        document.documentElement.style.setProperty("--bg-modal", dataCms["bg-modal"]);
        document.documentElement.style.setProperty("--bg-navbar", dataCms["bg-navbar"]);
        document.documentElement.style.setProperty("--bg-popup", dataCms["bg-popup"]);
        document.documentElement.style.setProperty("--bg-text-animation", dataCms["bg-text-animation"]);
        document.documentElement.style.setProperty("--bt-confirm", dataCms["bt-confirm"]);
        document.documentElement.style.setProperty("--bt-danger", dataCms["bt-danger"]);
        document.documentElement.style.setProperty("--bt-defult", dataCms["bt-defult"]);
        document.documentElement.style.setProperty("--bt-info", dataCms["bt-info"]);
        document.documentElement.style.setProperty("--bt-login", dataCms["bt-login"]);
        document.documentElement.style.setProperty("--bt-register", dataCms["bt-register"]);
        document.documentElement.style.setProperty("--bt-secondary", dataCms["bt-secondary"]);
        document.documentElement.style.setProperty("--bt-sucess", dataCms["bt-sucess"]);
        document.documentElement.style.setProperty("--bt-undefult", dataCms["bt-undefult"]);
        document.documentElement.style.setProperty("--bt-warning", dataCms["bt-warning"]);

        if(dataCms["google_key"] !== "") {
            TagManager.initialize({ gtmId: dataCms["google_key"] });
        }

        let url_domain = `${window.location.protocol}//${window.location.host}`;
        let myDynamicManifest = {
            "short_name": dataCms["manifest_short_name"],
            "name": dataCms["manifest_name"],
            "icons": [
                {
                "src": dataCms["manifest_icon_xl"],
                "type": "image/png, image/jpg, image/JPEG",
                "sizes": "512x512"
                },
                {
                "src": dataCms["manifest_icon_lg"],
                "type": "image/png, image/jpg, image/JPEG",
                "sizes": "192x192"
                },
                {
                "src": dataCms["manifest_icon_md"],
                "type": "image/png, image/jpg, image/JPEG",
                "sizes": "128x128"
                },
                {
                "src": dataCms["manifest_icon_sm"],
                "type": "image/png, image/jpg, image/JPEG",
                "sizes": "96x96"
                },
                {
                "src": dataCms["manifest_icon_xs"],
                "type": "image/png, image/jpg, image/JPEG",
                "sizes": "64x64"
                }
            ],
            "scope": dataCms["manifest_scope"],
            "start_url": url_domain,
            "display": dataCms["manifest_display"],
            "theme_color": dataCms["manifest_theme_color"],
            "background_color": dataCms["manifest_background_color"]
        };
            
        const stringManifest = JSON.stringify(myDynamicManifest);
        const blob = new Blob([stringManifest], {type: 'application/json'});
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector("#manifest-project").setAttribute("href", manifestURL);
        document.querySelector("#apple-touch-project").setAttribute("href", dataCms["manifest_icon_lg"]);
    };

    useEffect(() => {
        setStorage("allusion", document.referrer);
        setPathName(window.location.pathname);
        setLoadRoutes(true);
        // setSettingList(dataSetting);

        // if (dataSetting?.agent_popup !== undefined) {
        //     if (dataSetting.agent_popup.length > 0) {
        //         let promotionData = dataSetting.agent_popup.filter((item) => {
        //             return item.popup_position === "promotion";
        //         });

        //         let loginData = dataSetting.agent_popup.filter((item) => {
        //             return item.popup_position === "login";
        //         });

        //         let registerData = dataSetting.agent_popup.filter((item) => {
        //             return item.popup_position === "register";
        //         });

        //         setPopupPromotion(promotionData);
        //         setPopupLogin(loginData);
        //         setPopupRegister(registerData);
        //     }
        // }

        if (pathName !== "/") {
            if (isUserLoggedIn()) {
                loadHomeData().then((data) => {
                    if (data.code === 0 || data.code === 200) {
                        dispatch(setHomeData(data.result));
                    }
                });
            }

            dispatch(setLoadHome(true));
            dispatch(setLoadImages(true));
        }
    }, [pathName]);

    useEffect(() => {
        if(dataSetting?.cms !== undefined && dataSetting?.cms !== null) {
            removeStorage("data_cms");
            const dataCms = dataSetting?.cms;
            setLoadDataCms(dataCms);
            setProcessing(true);
            setProcessingNavbar(true);
        }
        else {
            if(dataSetting?.cms === undefined || dataSetting?.cms === null) {
                let checkCookie = getStorage("data_cms");

                if(checkCookie !== undefined && checkCookie !== null && checkCookie !== "") {
                    let data_cms = JSON.parse(checkCookie);
                    if(data_cms !== undefined && data_cms !== null) {
                        setLoadDataCms(data_cms);
                        setProcessingNavbar(true);
                    }
                    else {
                        setProcessingNavbar(false);
                    }
                }
            }
            else {
                setProcessingNavbar(false);
            }
        }
    }, [dataSetting?.cms]);

    useEffect(() => {
        if(iframe_load === true || iframe_load === "true") {
            setIframeStatus(true);
        }
        else {
            setIframeStatus(false);
        }
    }, [iframe_load]);

    useEffect(() => {
        const reloadCamp = () => {
            loadCampAll(99, true).then((result) => {
                dispatch(setCampAll(result));
            });
        };

        if(processing) {
            reloadCamp();
        }
    }, [processing]);

    useEffect(() => {
        if (imagesProcessing) {
            if(reduxAppCMS !== null) {
                if(reduxAppCMS?.section_games_rank === "1" || reduxAppCMS?.section_games_rank === 1) {
                    dispatch(setGameHitAll());
                }
    
                if(reduxAppCMS?.section_user_rank === "1" || reduxAppCMS?.section_user_rank === 1) {
                    dispatch(loadRank());
                }
            }

            dispatch(loadPromotionAll());
        }
    }, [reduxAppCMS, imagesProcessing]);

    useEffect(() => {
        if(dataSetting.menu_camp !== null) {
            let menuArr = dataSetting.menu_camp;
            if(menuArr.length > 0) {
                let item = menuArr[0];
                dispatch(setHomeMenuType({
                    id: 0,
                    renderPage: item.code,
                    pageTitle: item.title,
                    game_camp_image: item.game_camp_image,
                    column: {
                        desktop: item.column_desktop,
                        mobile: item.column_mobile
                    },
                    typeGame: item.game_camp_type
                }));
            }
        }
    }, [dataSetting.menu_camp]);

    return <>
        {
            loadRoutes && (
                iframeStatus ? 
                    <Container maxWidth="lg" className="bgContainer">
                        <VerticalRoutes dataSetting={dataSetting}></VerticalRoutes>
                    </Container>
                :
                <>
                    {
                        processingNavbar && (
                            <Navbar isUserLoggedIn={isUserLoggedIn()} isLargeScreen={isLargeScreen}></Navbar>
                        )
                    }

                    <div className="homePosition">
                        <BrannerSlide isLargeScreen={isLargeScreen}></BrannerSlide>
                        <TextAnimate></TextAnimate>
                    </div>

                    {
                        isUserLoggedIn() && (
                            <NavbarMenu isUserLoggedIn={isUserLoggedIn()} isLargeScreen={isLargeScreen}></NavbarMenu>
                        )
                    }

                    {
                        isUserLoggedIn() && (
                            popupGameData.show && (
                                (popupGameData.camp !== null && popupGameData.gamecode !== null) && (
                                    <GamePlayPopupApp popupGameData={popupGameData} isUserLoggedIn={isUserLoggedIn()}></GamePlayPopupApp>
                                )
                            )
                        )
                    }

                    <Container maxWidth="lg" className="bgContainer">
                        <div className={isUserLoggedIn() ? "wrapperActive" : "wrapper"}>
                            {
                                action.loadRoutes && (
                                    <VerticalRoutes dataSetting={dataSetting}></VerticalRoutes>
                                )
                            }

                            {
                                processing && (
                                    imagesProcessing && (
                                        <div className="cardMargin relative">
                                            <div className="left">
                                                <ListContact isLargeScreen={isLargeScreen}></ListContact>
                                            </div>
                                            <div className="cardMargin relative" style={{ marginTop: "0px" }}>
                                                <div className="left">
                                                    <FooterHome></FooterHome>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </Container>

                    {
                        processingNavbar && (
                            <FooterMenu isUserLoggedIn={isUserLoggedIn()} isLargeScreen={isLargeScreen}></FooterMenu>
                        )
                    }
                </>
            )
        }

        {
            (loadRoutes === false || processing === false) && <LoadSkeletonScreen></LoadSkeletonScreen>
        }

        {
            renderAlertBar()
        }

        {
            dialogData.statusShow && (
                <DialogAction dialogData={dialogData}></DialogAction>
            )
        }

        {
            conditionData.statusShow && (
                <ConditionPopup conditionData={conditionData}></ConditionPopup>
            )
        }

        {
            contactStatus && (<Contact></Contact>)
        }

        {
            reduxSpinner.status && (
                <LoadSpinner textProcess={reduxSpinner.textProcess}></LoadSpinner>
            )
        }

        {
            confirmData.show && (
                <ConfirmApp confirmData={confirmData}></ConfirmApp>
            )
        }

        {
            alertData.show && (
                <AlertApp alertData={alertData}></AlertApp>
            )
        }

        {
            reduxAppWheel && (
                <SpinWheel show={reduxAppWheel}></SpinWheel>
            )
        }

        {
            isUserLoggedIn && (
                <>
                    {
                        affiliate.show && (
                            <AffiateApp affiliate={affiliate} />
                        )
                    }

                    {
                        linkFriend.show && (
                            <InviteApp linkFriend={linkFriend} />
                        )
                    }
                </>
            )
        }

        <SnackbarSet ref={childRef} />
    </>;
}

export default Layouts;