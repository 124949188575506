import { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import Container from '@material-ui/core/Container';

// @Utility
import { setStorage, getStorage } from "./../../../@utility/app/storage";

// Check token
import { isToken, isUserLoggedIn } from "./../../../@core/auth/utils";

// Redux
import * as authActions from "./../../../@core/redux/actions/auth/index";
import { setShowPopupImage } from "./../../../@core/redux/actions/app/popup";

// Hooks
import useParameterUrl from "./../../../@utility/hooks/useParameterUrl";

// Sub Routes
import DepositRoute from "./pages/deposit/Layouts";
import WithdrawRoute from "./pages/withdraw/Layouts";
import ErrorRoute from "./pages/error/Routes.js";
import GameRoute from "./pages/game/Routes";

// Component
import AuthApp from "./components/Auth/index";
import ProfileApp from "./pages/profile/index";
import PromotionPopup from "./pages/deposit/component/PromotionPopup";
import PopupImages from "./../vertical/components/PopupImages";

// Routes page
import HomeApp from "./pages/home/index";
import GamePlayApp from "./../vertical/pages/game-play/index";
import RankIframeIndex from './pages/iframe-rank';

// const HomeApp = lazy(() => import("./pages/home/index"));
const ActivityApp = lazy(() => import("./pages/activity/index"));
const PromotionApp = lazy(() => import("./pages/promotion/index"));
const HistoryApp = lazy(() => import("./pages/history/index"));
const ShopApp = lazy(() => import("./pages/shop/index"));
const BagApp = lazy(() => import("./pages/bag/index"));
const BankApp = lazy(() => import("./pages/bank/index"));
const PasswordApp = lazy(() => import("./pages/password/index"));

const VerticalRoutes = ({ dataSetting }) => {
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    // const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const parsed = useParameterUrl();
    const { login, register, affiliate, refer, ref, market } = parsed;
    const reduxProfile = useSelector(state => state.reduxProfile);
    const reduxPromotionPopup = useSelector(state => state.reduxPromotion);
    const popupImageShow = useSelector(state => state.reduxAppPopup.popupImage);
    const [dataPopup, setDataPopup] = useState([]);
    const imagesProcessing = useSelector(state => state.reduxAppProcessing.images);

    const routesRouter = () => {
        return (
            <Suspense fallback={null}>
                {
                    isToken() ?
                        (dataSetting === null || dataSetting === undefined) ?
                            <Switch>
                                <Route
                                    path="/"
                                    exact
                                    render={() =>
                                        <HomeApp agent_setting_menu={dataSetting?.agent_setting_menu}></HomeApp>
                                    }>
                                </Route>
                                <Route path="*" >
                                    <Redirect to="/" />
                                </Route>
                            </Switch>
                            :
                            <Switch>
                                <Route
                                    path="/shop"
                                    exact
                                    render={() => isUserLoggedIn() ?
                                        <ShopApp></ShopApp> : <Redirect to="/" />
                                    }>
                                </Route>
                                <Route
                                    path="/activity"
                                    exact
                                    render={() => isUserLoggedIn() ?
                                        <ActivityApp></ActivityApp> : <Redirect to="/" />
                                    }>
                                </Route>
                                <Route
                                    path="/password"
                                    exact
                                    render={() => isUserLoggedIn() ?
                                        <PasswordApp></PasswordApp> : <Redirect to="/" />
                                    }>
                                </Route>
                                <Route
                                    path="/bank"
                                    exact
                                    render={() => isUserLoggedIn() ?
                                        <BankApp></BankApp> : <Redirect to="/" />
                                    }>
                                </Route>
                                {/* <Route
                                    path="/rank"
                                    exact
                                    render={() => (dataSetting?.agent_setting_menu?.menu_statistics === 1) ?
                                        <RankApp menu_status={dataSetting?.agent_setting_menu?.menu_statistics}></RankApp> : <Redirect to="/" />
                                    }>
                                </Route> */}
                                {/* <Route
                                    path="/inbox"
                                    exact
                                    render={() => (dataSetting?.agent_setting_menu?.menu_mailbox === 1) ?
                                        <InboxApp menu_status={dataSetting?.agent_setting_menu?.menu_mailbox}></InboxApp> : <Redirect to="/" />
                                    }>
                                </Route> */}
                                <Route
                                    path="/bag"
                                    exact
                                    render={() => isUserLoggedIn() ?
                                        <BagApp></BagApp> : <Redirect to="/" />
                                    }>
                                </Route>
                                <Route
                                    path="/shop"
                                    exact
                                    render={() => (dataSetting?.agent_setting_menu?.menu_shop === 1) ?
                                        <ShopApp menu_status={dataSetting?.agent_setting_menu?.menu_shop}></ShopApp> : <Redirect to="/" />
                                    }>
                                </Route>
                                <Route
                                    path="/history"
                                    exact
                                    render={() => isUserLoggedIn() ?
                                        <HistoryApp></HistoryApp> : <Redirect to="/" />
                                    }
                                >
                                </Route>
                                <Route
                                    path="/withdraw"
                                    render={() => (dataSetting?.agent_setting_menu?.menu_withdraw === 1) ?
                                        <WithdrawRoute menu_status={dataSetting?.agent_setting_menu?.menu_withdraw}></WithdrawRoute> : <Redirect to="/" />
                                    }>
                                </Route>
                                <Route
                                    path="/deposit"
                                    render={() => (dataSetting?.agent_setting_menu?.menu_deposit === 1) ?
                                        <DepositRoute menu_status={dataSetting?.agent_setting_menu?.menu_deposit} agentData={dataSetting}></DepositRoute> : <Redirect to="/" />
                                    }>
                                </Route>
                                <Route
                                    path="/promotion"
                                    exact
                                    render={() => (dataSetting?.agent_setting_menu?.menu_promotion === 1) ?
                                        <PromotionApp menu_status={dataSetting?.agent_setting_menu?.menu_promotion}></PromotionApp> : <Redirect to="/" />
                                    }>
                                </Route>
                                <Route path="/iframe/rank">
                                    <RankIframeIndex iframe_load="true"></RankIframeIndex>
                                </Route>
                                <Route path="/games/camp/:camp/:gamecode" exact>
                                    <GamePlayApp></GamePlayApp>
                                </Route>
                                <Route path="/games">
                                    <GameRoute></GameRoute>
                                </Route>
                                <Route path="/" exact>
                                    <HomeApp agent_setting_menu={dataSetting?.agent_setting_menu}></HomeApp>
                                </Route>
                                <Route path="*" >
                                    <Redirect to="/" />
                                </Route>
                            </Switch>
                        :
                        <Switch>
                            <Route path="/error">
                                <ErrorRoute></ErrorRoute>
                            </Route>
                            <Route path="*" >
                                <Redirect to="/error/token" />
                            </Route>
                        </Switch>
                }
            </Suspense>
        );
    };

    useEffect(() => {
        // SHOW หน้าเข้าสู่ระบบ
        if (login === true || login === "true") {
            dispatch(authActions.openAuthenApp({ tab: "login", show: true }));
        }

        // SHOW หน้าสมัครสมาชิก
        if (register === true || register === "true") {
            dispatch(authActions.openAuthenApp({ tab: "register", show: true }));
        }

        // เก็บข้อมูลเพื่อนที่เชิญ
        if (affiliate !== undefined) {
            setStorage("affiliate", affiliate)
        }

        // อ้างอิง
        if (refer !== undefined) {
            setStorage("refer", refer)
        } else if (ref !== undefined) {
            setStorage("refer", ref)
        }

        // การตลาด
        if (market !== undefined) {
            setStorage("market", market)
        }
    }, [login, register, affiliate, refer, ref, dispatch]);

    useEffect(() => {
        const isNumber = (n) => { 
            return /^-?[\d.]+(?:e-?\d+)?$/.test(n); 
        };

        if(dataSetting.agent_image !== null) {
            const dataAgentImages = dataSetting.agent_image;
            const pathName = window.location.pathname;
            const dataImagesPopup = dataAgentImages.filter((item) => {
                return item.image_position === "popup";
            });

            if(dataImagesPopup.length > 0) {
                const dataArr = dataImagesPopup.filter((item) => {
                    let timeStorage = getStorage(item.id);
                    let statusPopup = false;
                    let timeNext = (new Date().getTime() / 1000) + item.image_frequency;
                    if(timeStorage === undefined || timeStorage === null) {
                        setStorage(item.id, timeNext);
                        statusPopup = true;
                    }
                    else {
                        if(isNumber(timeStorage)) {
                            let timeNow = (new Date().getTime() / 1000);
                            if(timeNow > Number(timeStorage)) {
                                setStorage(item.id, (Number(timeStorage) + item.image_frequenc));
                                statusPopup = true;
                            }
                            else {
                                statusPopup = false;
                            }
                        }
                        else {
                            setStorage(item.id, timeNext);
                            statusPopup = true;
                        }
                    }

                    if(statusPopup) {
                        if(pathName === "/") {
                            return item.image_path === "" || item.image_path === "/";
                        }
                        else {
                            if(item.image_path === "*") {
                                return item.image_path;
                            }
                            else {
                                return item.image_path === pathName;
                            }
                        }
                    }
                });

                if(dataArr.length > 0) {
                    setDataPopup(dataArr);
                    dispatch(setShowPopupImage(true));
                }
            }
        }
        else {
            setDataPopup([]);
        }
    }, [dataSetting.agent_image, window.location.href]);

    return (
        <>
            {
                routesRouter()
            }

            <AuthApp></AuthApp>

            {
                isUserLoggedIn() && (
                    reduxProfile.show && (
                        <ProfileApp reduxProfile={reduxProfile}></ProfileApp>
                    )
                )
            }

            {
                isUserLoggedIn() && (
                    reduxPromotionPopup.show && (
                        <PromotionPopup reduxPromotionPopup={reduxPromotionPopup}></PromotionPopup>
                    )
                )
            }

            {
                imagesProcessing && (
                    popupImageShow && (
                        dataPopup.length > 0 && (
                            <PopupImages popupData={dataPopup} />
                        )
                    )
                )
            }
        </>
    );
};

export default VerticalRoutes;