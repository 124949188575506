import { useState, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

// auth
import { isUserLoggedIn } from "./../../../../../../../@core/auth/utils";

// @utility
import alertText from "./../../../../../../../@utility/app/alertText";
// import currencyFormat from "./../../../../../../../@utility/numbers/currencyFormat";

// Redux
import { setDataDialog } from "./../../../../../../../@core/redux/actions/app/dialog";
import { setDepositData } from "./../../../../../../../@core/redux/actions/deposit/index";
// import { setDataCondition } from "./../../../../../../../@core/redux/actions/app/conditions";
import { setDataContact } from "./../../../../../../../@core/redux/actions/app/contact";
import { setStatusSpinner } from "./../../../../../../../@core/redux/actions/app/spinner";

// Services
import { cancelPromotion } from "./../../../../../../../@core/services/promotionService";
import { loadDepositFirst } from "./../../../../../../../@core/services/depositService";

//  CSS
import styles from "./../../../../assets/css/Style.module.css";
import modalStyles from "./../../../../assets/css/Modal.module.css";
import buttonStyles from "./../../../../assets/css/Button.module.css";
// import bagdeStyles from "./../../../../assets/css/Bagde.module.css";

// Components
import SnackbarSet from "./../../../../components/SnackbarSet";
import PromotionAlertContent from './../../../../components/PromotionAlertContent';

export default function MenuHome({ depositData, reduxAppCMS, gotoPage, dataBank }) {
    const homeData = useSelector(state => state.reduxHome.result);
    const [depositMenu, setDepositMenu] = useState(null);
    let snackbarJson = {};
    const dispatch = useDispatch();
    const childRef = useRef();

    const useStyles = makeStyles((theme) => ({
        menuText: {
            fontSize: "20px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white"
        },
        menuList: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            borderRadius: "10px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-warning"] : "#FFE000",
            padding: "20px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            cursor: "pointer",
            position: "relative",
            fontSize: "18px",
            height: "175px",
            '&:hover': {
                top: "2px"
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: "16px",
                height: "140px",
                padding: "8px"
            }
        },
        imgSize: {
            width: "100px",
            height: "auto",
            [theme.breakpoints.down('xs')]: {
                width: "80px"
            }
        },
        menuComment: {
            fontSize: "18px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white"
        },
        processText: {
            fontSize: "16px",
            marginTop: "6px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            [theme.breakpoints.down('xs')]: {
                fontSize: "14px"
            }
        },
        textBottom: {
            [theme.breakpoints.down('xs')]: {
                marginTop: "0px"
            }
        }
    }));

    const classes = useStyles();

    const openBox = (data) => {
        let dialogJson = {
            pageType: "promotion",
            statusShow: true,
            type: "html",
            image: null,
            title: "เงื่อนไขโปรโมชั่น",
            content: data.content_text,
            action: null
        };

        dialogBoxData(dialogJson);
    };

    const dialogBoxData = (dialogJson) => {
        dispatch(setDataDialog(dialogJson));
    }

    const setCancelPromotion = () => {
        if (homeData.balance <= process.env.REACT_APP_BALANCE_LIMIT) {
            // setLoadding(true);
            dispatch(setStatusSpinner({status: true, textProcess: null}));
            cancelPromotion().then((data) => {
                const statusCode = data.code;
                dispatch(setStatusSpinner({status: false, textProcess: null}));
                // setLoadding(false);

                if (statusCode === 200 || statusCode === 0) {
                    snackbarJson = {
                        statusShow: true,
                        statusAlert: "success",
                        titleAlert: "แจ้งเตือน",
                        textAlert: "ยกเลิกรับโบนัสสำเร็จ !"
                    };

                    loadDepositPromotion();
                    childRef.current.snackbar(snackbarJson);
                }
                else {
                    if (statusCode === 2 || statusCode === 4) {
                        snackbarJson = alertText(statusCode);
                        childRef.current.snackbar(snackbarJson);

                        setTimeout(function () {
                            window.location.href = `/?web=${process.env.REACT_APP_DEFAULT_AGENT_CODE}`;
                        }, 5000);
                    }
                    else {
                        snackbarJson = alertText(statusCode);
                        childRef.current.snackbar(snackbarJson);
                    }
                }
            });

        }
        else {
            snackbarJson = {
                statusShow: true,
                statusAlert: "warning",
                titleAlert: "แจ้งเตือน",
                textAlert: `ไม่สามารถยกเลิกโบนัสได้ เนื่องจากเครดิตของคุณลูกค้าคงเหลือมากกว่า ${process.env.REACT_APP_BALANCE_LIMIT} เครดิต`
            };

            childRef.current.snackbar(snackbarJson);
        }
    };

    const loadDepositPromotion = () => {
        loadDepositFirst().then((data) => {
            const statusCode = data.code;
            if (statusCode === 200 || statusCode === 0) {
                dispatch(setDepositData(data.result));
            }
            else {
                if (statusCode === 2 || statusCode === 4) {
                    snackbarJson = alertText(statusCode);
                    childRef.current.snackbar(snackbarJson);

                    setTimeout(function () {
                        window.location.href = `/?web=${process.env.REACT_APP_DEFAULT_AGENT_CODE}`;
                    }, 5000);
                }
                else {
                    snackbarJson = alertText(statusCode);
                    childRef.current.snackbar(snackbarJson);
                }
            }
        });
    };

    const openContact = () => {
        dispatch(setDataContact(true));
    };

    const renderMenuList = (item) => {
        if(isUserLoggedIn()) {
            if(depositData?.mode === "deposit") {
                if(item.key === "automatic") {
                    if(depositData?.channel.automatic === false) {
                        return null;
                    }
                }
                else if(item.key === "truemoney") {
                    if(depositData?.channel.truemoney === false) {
                        return null;
                    }
                }
                else if(item.key === "bank") {
                    if(depositData?.channel.manual === false) {
                        return null;
                    }
                }
                else {
                    return null;
                }
            }
        }

        return (
            <Grid key={item.key} item xs={6}>
                <div className="center">
                    <div className={styles.boxMarginMediumTop}>
                        <div className={classes.menuList} onClick={() => { gotoPage(item) }}>
                            <div className="relative">
                                <img src={item.icon} className={classes.imgSize} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                <p className={classes.textBottom} style={{ marginBottom: "0px" }}>
                                    {
                                        item.name
                                    }
                                </p>
                                <div className={classes.processText}>
                                    {
                                        item.process
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
        );
    };

    const renderProcessing = () => {
        if(isUserLoggedIn()) {
            if (depositMenu === null || depositMenu?.mode === "deposit") {
                return (
                    <>
                        <div className={modalStyles.boxTitle}>
                            เติมเงิน
                        </div>
                        <div className={[styles.boxMarginTop, styles.boxContentTitle].join(" ")} style={{ textAlign: "center" }}>
                            กรุณากดเลือกวิธีการเติมเงิน
                        </div>
                        <div className={styles.boxMarginFormTop}>
                            <Grid container spacing={2} alignItems="center">
                                {
                                    dataBank.map((item) => (
                                        renderMenuList(item)
                                    ))
                                }
                            </Grid>
                        </div>
                    </>
                );
            }
            else if (depositMenu.mode === "promotion") {
                return (
                    <>
                        <div className={modalStyles.boxTitle}>
                            ติดสถานะรับโปรโมชั่น
                        </div>
                        <div className={styles.boxMarginFormTop}>
                            <div className={[styles.boxDetailTop, "relative"].join(" ")}>
                                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <div className={styles.promotionBoxContentTitle}>
                                            กำลังรอดำเนินการ
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <button className={[buttonStyles.btnConfirm, buttonStyles.btnConditionSize].join(" ")}  onClick={() => { openBox(depositMenu) }}>
                                            อ่านเงื่อนไข
                                        </button>
                                    </Grid>
                                </Grid>
                                <PromotionAlertContent promotionData={depositMenu}></PromotionAlertContent>
                            </div>
                            <div className={[styles.boxMarginFormTop, "center"].join(" ")}>
                                <button className={[buttonStyles.btnRedirect, buttonStyles.btnCancel, "center"].join(" ")} onClick={() => { setCancelPromotion() }}>
                                    ยกเลิกรับโปร
                                </button>
                            </div>
                        </div>
                    </>
                );
            }
            else if (depositMenu.mode === "withdraw") {
                return (
                    <>
                        <div className={modalStyles.boxTitle}>
                            รอดำเนินการ
                        </div>
                        <Grid container spacing={1} className={styles.boxMarginFormTop}>
                            <Grid item xs={12}>
                                <div className={styles.boxDetailTop}>
                                    <div className={styles.promotionBoxContentTitle}>
                                        ติดสถานะถอนเงิน
                                    </div>
                                    <div className={styles.boxDetailAmount} style={{ marginTop: "10px" }}>
                                        ไม่สามารถเติมเงินได้ เนื่องจากมีรายการถอนเงินที่ยังไม่ได้ดำเนินการ
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </>
                );
            }
            else {
                return (
                    <>
                        <div className={modalStyles.boxTitle}>
                            รอดำเนินการ
                        </div>
                        <Grid container spacing={1} className={styles.boxMarginFormTop}>
                            <Grid item xs={12}>
                                <div className={styles.boxDetailTop}>
                                    <div className={styles.promotionBoxContentTitle}>
                                        มีข้อผิดพลาด
                                    </div>
                                    <div className={styles.boxDetailAmount} style={{ marginTop: "10px" }}>
                                        ไม่สามารถทำรายการเติมเงินได้ กรุณาติดต่อแอดมินบริการลูกค้า
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </>
                );
            }
        }
        else {
            return (
                <>
                    <div className={modalStyles.boxTitle}>
                        เติมเงิน
                    </div>
                    <div className={[styles.boxMarginTop, styles.boxContentTitle].join(" ")} style={{ textAlign: "center" }}>
                        กรุณากดเลือกวิธีการเติมเงิน
                    </div>
                    <div className={styles.boxMarginFormTop}>
                        <Grid container spacing={2} alignItems="center">
                            {
                                dataBank.map((item) => (
                                    renderMenuList(item)
                                ))
                            }
                        </Grid>
                    </div>
                </>
            );
        }
    };

    useEffect(() => {
        setDepositMenu(depositData);
        // setLoadding(false);
    }, [dispatch, depositData]);

    return (
        <>
            <SnackbarSet ref={childRef} />
            {
                renderProcessing()
            }

            <br /><br />
            <div className={[styles.boxComemntTop, styles.center, styles.white].join(" ")}>
                ระบบฝากถอนรวดเร็วทันใจ ความปลอดภัยสูง หากพบปัญหากรุณาติดต่อ <span className={styles.contactService} onClick={openContact}>พนักงานบริการลูกค้า</span>
            </div>
        </>
    );
}