import { lazy } from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

// Component
// import VideoMain from './../../components/VideoMain';
// import FooterMenu from "./../../components/FooterMenu";
import NotiEvent from "./../../components/NotiEvent";

// Routes page
const GameCampApp = lazy(() => import("./page/game-camp/index"));
// const GameAnalyzeApp = lazy(() => import("./page/game-analyze/index"));
const GameLikeApp = lazy(() => import("./page/game-like/index"));
const GameSearchApp = lazy(() => import("./page/game-search/index"));
// const GameAllApp = lazy(() => import("./page/game-all/index"));
const GameLastApp = lazy(() => import("./page/game-last/index"));

export default function Routes() {
    const { path } = useRouteMatch(); // useRouteMatch สามารถเปลี่ยนได้แบบ Dynamic

    return (
        <>
            <Switch>
                <Route path={`${path}/last`} exact>
                    <GameLastApp></GameLastApp>
                </Route>
                <Route path={`${path}/search`} exact>
                    <GameSearchApp></GameSearchApp>
                </Route>
                <Route path={`${path}/like/all`} exact>
                    <GameLikeApp></GameLikeApp>
                </Route>
                {/* <Route path={`${path}/analyze/:type`} exact>
                    <GameAnalyzeApp></GameAnalyzeApp>
                </Route> */}
                <Route path={`${path}/camp/:type`} exact>
                    <GameCampApp></GameCampApp>
                </Route>
                <Route path="*" >
                    <Redirect to="/" />
                </Route>
            </Switch>

            <NotiEvent></NotiEvent>
        </>
    )
};