import { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Hooks
import useParameterUrl from "./../../../../@utility/hooks/useParameterUrl";

// CSS
import styles from "./../assets/css/Style.module.css";

export default function ListContact({ isLargeScreen }) {
    // const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const homeData = useSelector(state => state.reduxHome.result);
    const [jsonContact, setJsonContact] = useState(null);
    const parsed = useParameterUrl();
    const { play_game } = parsed;
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);
    const themes = useTheme();
    const isSmallScreen = useMediaQuery(themes.breakpoints.down('xs'));

    const useStyles = makeStyles((theme) => ({
        // cardGameBody: {
        //     // background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "white",
        //     borderRadius: "15px",
        //     padding: "9px 18px",
        //     [theme.breakpoints.down('xs')]: {
        //         borderRadius: "0px",
        //     }
        // },
        cardGameMenu: {
            padding: "30px",
            // padding: "30px",
            [theme.breakpoints.down('xs')]: {
                padding: "25px 15px"
            }
        },
        cardContact: {
            position: "relative",
            // background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "white",
            background: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            // boxShadow: "0px 2px 10px rgba(68, 68, 68, 0.2)",
            borderRadius: "10px",
            padding: "10px",
            height: "140px",
            cursor: "pointer",
            '&:hover': {
                top: "2px"
            },
            [theme.breakpoints.down('xs')]: {
                height: "70px"
            }
        },
        lineText: {
            fontSize: "20px",
            color: "#00BB16",
            fontWeight: "700",
            [theme.breakpoints.down('xs')]: {
                fontSize: "13px"
            }
        },
        liveChatText: {
            fontSize: "20px",
            color: "#FF0000",
            fontWeight: "700",
            [theme.breakpoints.down('xs')]: {
                fontSize: "13px"
            }
        },
        phoneText: {
            fontSize: "20px",
            color: "#299ed4",
            fontWeight: "700",
            [theme.breakpoints.down('xs')]: {
                fontSize: "13px"
            }
        },
        cardText: {
            fontSize: "20px",
            color: reduxAppCMS !== null ? reduxAppCMS["bg-main"] : "#4B4B4B",
            fontWeight: "700",
            [theme.breakpoints.down('xs')]: {
                fontSize: "13px"
            }
        },
        contactSize: {
            width: "90px",
            height: "auto",
            [theme.breakpoints.down('xs')]: {
                width: "50px"
            }
        },
        contactPosition: {
            marginTop: "-30px",
            marginBottom: "10px"
        },
        characterPosition: {
            position: "absolute",
            right: "0px",
            bottom: "-4px"
        },
        characterSize: {
            width: "320px",
            height: "auto",
            borderRadius: "0px 0px 15px 0px",
            [theme.breakpoints.down('xs')]: {
                width: "148px",
                borderRadius: "unset"
            }
        },
        gridBreak: {
            display: "none",
            [theme.breakpoints.down('xs')]: {
                display: "block"
            }
        },
        contactPhone: {
            [theme.breakpoints.down('xs')]: {
                marginTop: "10px"
            }
        }
    }));

    const classes = useStyles();

    const loadLiveChat = () => {
        window.LiveChatWidget.call("maximize");
        window.LiveChatWidget.call('set_customer_name', `${homeData !== null ? homeData.username : "-"}`);
        window.LiveChatWidget.call("set_session_variables", {
            username: (homeData !== null ? homeData.username : "-"),
            balance: (homeData !== null ? homeData.balance : 0),
            phone: (homeData !== null ? (homeData.phone === "" ? "-" : homeData.phone) : "-")
        });
    };

    const gotoContact = (data, type) => {
        if (type === "line") {
            window.open(data.channel_link, "_blank");
        }
        else {
            loadLiveChat();
        }
    };

    const callContact = (data) => {
        window.open('tel:' + data.channel_appid, "_parent");
    }

    useEffect(() => {
        if (dataSetting?.agent_contact !== undefined) {
            setJsonContact(dataSetting.agent_contact);
        }
    }, [dataSetting]);

    // console.log(jsonContact)

    return (
        (play_game !== "true" && play_game !== true) && (
            <>
                <div className={styles.cardOtherListBody} style={{ padding: "0px", borderBottom: `1px solid ${reduxAppCMS !== null ? reduxAppCMS["bg-foter"] : "white"}` }}>
                    <div className="relative">
                        <div className={classes.cardGameMenu}>
                            <div className={styles.cardOtherListHeadText}>
                                ติดต่อเรา พนักงานบริการ 24 ชั่วโมง
                            </div>
                            <br /><br />
                            <Grid container alignItems="center" spacing={isLargeScreen ? 3 : 2}>
                                {
                                    jsonContact !== null && (
                                        jsonContact.map((item, index) => {
                                            return (
                                                item.contact_channel === 0 ?
                                                    <Grid key={index} item xs={4} sm={3}>
                                                        <div className={[classes.cardContact, "center", classes.contactPhone].join(" ")} onClick={() => gotoContact(item, "line")}>
                                                            <div className={classes.contactPosition}>
                                                                <img className={classes.contactSize} src="/images/icon/line_contact_home.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                            </div>
                                                            <div className={classes.lineText}>
                                                                ไลน์
                                                            </div>
                                                            <div className={classes.cardText}>
                                                                {/* @ty711 */}
                                                                {
                                                                    item.channel_appid
                                                                }
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    :
                                                    item.contact_channel === 2 ?
                                                        <Grid key={index} item xs={4} sm={3}>
                                                            <div className={[classes.cardContact, "center", classes.contactPhone].join(" ")} onClick={() => callContact(item)}>
                                                                <div className={classes.contactPosition}>
                                                                    <img className={classes.contactSize} src="/images/icon/phone_contact_home.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                                </div>
                                                                <div className={classes.phoneText}>
                                                                    เบอร์โทรศัพท์
                                                                </div>
                                                                <div className={classes.cardText}>
                                                                    {
                                                                        item.channel_appid
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        :
                                                        <Grid key={index} item xs={4} sm={3}>
                                                            <div className={[classes.cardContact, "center", classes.contactPhone].join(" ")} onClick={() => gotoContact(item, "liveChat")}>
                                                                <div className={classes.contactPosition}>
                                                                    <img className={classes.contactSize} src="/images/icon/livechat_contact_home.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                                </div>
                                                                <div className={classes.liveChatText}>
                                                                    ไลฟ์แชท
                                                                </div>
                                                                <div className={classes.cardText}>
                                                                    คุยเเชทสด
                                                                </div>
                                                            </div>
                                                        </Grid>
                                            )
                                        })
                                    )
                                }
                            </Grid>
                        </div>

                        {/* <div className={classes.characterPosition}>
                            {
                                reduxAppCMS !== null && (
                                    <img className={classes.characterSize} src={reduxAppCMS["image_contact"]} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                )
                            }
                        </div> */}
                    </div>
                </div>
            </>
        )
    );
}