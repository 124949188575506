import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
    Tabs,
    Tab,
    Typography,
    Box,
    Grid
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

// @utility
import currencyFormat from "../../../../../../@utility/numbers/currencyFormat";
import alertText from "../../../../../../@utility/app/alertText";

// Redux
import { setDataAlert } from "../../../../../../@core/redux/actions/app/alert";
import { setStatusSpinner } from "../../../../../../@core/redux/actions/app/spinner";
import { setHomeData } from "../../../../../../@core/redux/actions/home/index";

// Services
import { claimAll } from "../../../../../../@core/services/affiliateService";
import { loadHomeData } from "../../../../../../@core/services/homeService";


//  CSS
import styles from "./../../../assets/css/Style.module.css";
import modalStyles from "./../../../assets/css/Modal.module.css";
import buttonStyles from "./../../../assets/css/Button.module.css";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

export default function CliamAction({ dataArr, onAffiliate, loadData, reduxAppCMS }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [hideMenuCashback, setHideMenuCashback] = useState(false);
    const [hideMenuCom, setHideMenuCom] = useState(false);
    let snackbarJson = {};

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            opacity: "1 !important",
            color: reduxAppCMS !== null ? `${reduxAppCMS["bt-defult"]} !important` : "white !important",
            padding: "0px 20px",
            [theme.breakpoints.down('xs')]: {
                padding: "0px 0px"
            }
        },
        tabText: {
            fontSize: "18px",
            '&:hover': {
                color: reduxAppCMS !== null ? reduxAppCMS["bt-warning"] : "#FFD600"
            }
        },
        tabTextActive: {
            fontSize: "18px",
            color: reduxAppCMS !== null ? `${reduxAppCMS["bt-warning"]} !important` : "#FFD600 !important",
            opacity: "1 !important"
            // borderBottom: "2px solid #FFCC01 !important"
        },
        tabContent: {
            padding: "30px 0px"
        },
        selctType: {
            padding: "12px 18px",
            fontSize: "18px",
            background: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            borderRadius: "10px",
            marginBottom: "10px"
        },
        contentText: {
            fontSize: "16px",
            fontWeight: "600"
        },
        btnHistory: {
            position: "relative",
            background: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            borderRadius: "10px",
            padding: "8px 16px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            fontSize: "16px",
            cursor: "pointer",
            '&:hover': {
                top: "2px"
            }
        },
        boxAmount: {
            border: `2px solid ${reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white"}`,
            padding: "20px",
            borderRadius: "10px"
        },
        boxAmountTitle: {
            fontSize: "20px",
            fontWeight: "600"
        },
        boxAmountResult: {
            fontSize: "26px",
            fontWeight: "600",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-warning"] : "#FFD600"
        },
        boxCondition: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            padding: "22px 12px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            borderRadius: "15px"
        },
        boxConditionTitle: {
            fontSize: "18px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-danger"] : "#FF0000",
            fontWeight: "600",
            marginBottom: "10px"
        },
        indicator: {
            backgroundColor: "#FFCC01"
        }
    }));

    const classes = useStyles();

    const gotoPage = (type) => {
        onAffiliate(
            {
                tab: "affiliate",
                show: false,
                type: null
            }
        );

        history.push(`/history?tableType=${type}`)
    };

    const renderContentCashback = () => (
        <>
            <div className={classes.selctType}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        ยอดเสียสะสมทั้งหมด
                    </Grid>
                    <Grid item>
                        {
                            dataArr !== null ?
                                currencyFormat(dataArr.property_cashback.claim_all, 2)
                                :
                                "0.00"
                        }
                    </Grid>
                </Grid>
            </div>
            <div className={classes.selctType} style={{ background: reduxAppCMS !== null ? reduxAppCMS["bt-danger"] : "#FF0000" }}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        ยอดเสียสะสมวันนี้
                    </Grid>
                    <Grid item>
                        {
                            dataArr !== null ?
                                currencyFormat(dataArr.property_cashback.claim_day, 2)
                                :
                                "0.00"
                        }
                    </Grid>
                </Grid>
            </div>
            <div className={styles.boxMarginTop}>
                <div className={classes.contentText}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            คืนยอดเสีย   {
                                dataArr !== null ?
                                    dataArr.property_cashback.percent
                                    :
                                    0
                            }%
                        </Grid>
                        <Grid item>
                            <div className={classes.btnHistory} onClick={() => { gotoPage("cashback") }}>
                                ประวัติรับยอดเสีย
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className={styles.boxMarginFormTop}>
                <div className={classes.boxAmount}>
                    <div className="center">
                        <div className={classes.boxAmountTitle}>
                            ยอดเสียของคุณ
                        </div>
                        <div className={styles.boxMarginTop}>
                            <div className={classes.boxAmountResult}>
                                {
                                    dataArr !== null ?
                                        (currencyFormat((Number(dataArr.property_cashback.claim_all) + Number(dataArr.property_cashback.claim_day)), 2))
                                        :
                                        "0.00"
                                }
                            </div>
                            {
                                (currencyFormat((Number(dataArr.property_cashback.claim_all) + Number(dataArr.property_cashback.claim_day)), 2)) > dataArr.property_cashback.max_withdraw && (
                                    <div className={styles.boxMarginTop} style={{ color: "rgb(255 84 84)" }}>
                                        *สามารถกดรับยอดเสียสูงสุดได้ไม่เกิน {currencyFormat(Number(dataArr.property_cashback.max_withdraw))} บาท*
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.boxMarginMediumTop}>
                    <div className="center">
                        <button className={[buttonStyles.btnRedirect, buttonStyles.btnConfirm].join(" ")} onClick={() => { claimData("/customer/property/cashback/claim", (dataArr !== null ? (Number(dataArr.property_cashback.claim_all) + Number(dataArr.property_cashback.claim_day)) : 0)) }}>
                            รับยอดเสีย
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles.boxMarginFormTop}>
                <div className={classes.boxCondition}>
                    <div className={classes.contentText} style={{ fontWeight: "400" }}>
                        <div className={classes.boxConditionTitle}>
                            *กติกาการรับยอดเสีย*
                        </div>

                        {
                            dataArr !== null && (
                                ReactHtmlParser(dataArr.property_cashback.condition)
                            )
                        }

                        {/* การถอน “ยอดเสีย”
                        <br />
                        1. กดรับคืน “ยอดเสีย” ได้ วันละ 1 ครั้ง เวลาไหนก็ได้

                        2. กรณีถอนเข้า “กระเป๋าเครดิต” จะต้องมียอดเสียที่ได้รับขั้นต่ำ 1 บาท และ มียอดในกระเป๋าเครดิตต่ำกว่า 10บาท ถึงจะถอนได้

                        3. กรณีถอนเป็น “เงินสด” จะต้องมียอดขั้นต่ำ 1 บาท ถึงจะถอนได้
                        <br />
                        กฏกติกา “รับยอดเสีย”
                        <br /> <br />
                        1. หากเลือกรับ “ยอดเสีย” แล้วจะไม่สามารถรับ “ค่าคอมมิชชั่น” ในวันเดียวกันได้อีก (เลือกรับได้อย่างใดอย่างหนึ่งในวันนั้นๆ)
                        <br />
                        2. เมื่อกดรับ “ยอดเสีย” แล้ว ระบบจะทำการ รีเซ็ต “ค่าคอมมิชชั่น” เป็น 0 ทันที
                        <br />
                        3. “ยอดเสีย” ที่ได้รับ ไม่ต้องทำยอดสามารถถอนได้ทันที
                        <br />
                        4. หากพบเห็นการเดิมพันที่ผิดปกติระบบจะทำการตัดเครดิตโดย
                        อัตโนมัติทันทีและไม่สามารถคืนเงินได้ */}
                    </div>
                </div>
            </div>
        </>
    );

    const renderContentCommission = () => (
        <>
            <div className={classes.selctType}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        ค่าคอมสะสมทั้งหมด
                    </Grid>
                    <Grid item>
                        {
                            dataArr !== null ?
                                currencyFormat(dataArr.property_commission.claim_all, 2)
                                :
                                "0.00"
                        }
                    </Grid>
                </Grid>
            </div>
            <div className={classes.selctType} style={{ background: "#8000FF" }}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        ค่าคอมสะสมวันนี้
                    </Grid>
                    <Grid item>
                        {
                            dataArr !== null ?
                                currencyFormat(dataArr.property_commission.claim_day, 2)
                                :
                                "0.00"
                        }
                    </Grid>
                </Grid>
            </div>
            <div className={styles.boxMarginTop}>
                <div className={classes.contentText}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            ค่าคอม {
                                dataArr !== null ?
                                    dataArr.property_commission.percent
                                    :
                                    0
                            }%
                        </Grid>
                        <Grid item>
                            <div className={classes.btnHistory} onClick={() => { gotoPage("commission") }}>
                                ประวัติรับค่าคอม
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className={styles.boxMarginFormTop}>
                <div className={classes.boxAmount}>
                    <div className="center">
                        <div className={classes.boxAmountTitle}>
                            ค่าคอมของคุณ
                        </div>
                        <div className={styles.boxMarginTop}>
                            <div className={classes.boxAmountResult}>
                                {
                                    dataArr !== null ?
                                        (currencyFormat((Number(dataArr.property_commission.claim_all) + Number(dataArr.property_commission.claim_day)), 2))
                                        :
                                        "0.00"
                                }
                            </div>
                        </div>
                        {
                            (currencyFormat((Number(dataArr.property_commission.claim_all) + Number(dataArr.property_commission.claim_day)), 2)) > dataArr.property_commission.max_withdraw && (
                                <div className={styles.boxMarginTop} style={{ color: "rgb(255 84 84)" }}>
                                    *สามารถกดรับค่าคอมสูงสุดได้ไม่เกิน {currencyFormat(Number(dataArr.property_commission.max_withdraw))} บาท*
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className={styles.boxMarginMediumTop}>
                    <div className="center">
                        <button className={[buttonStyles.btnRedirect, buttonStyles.btnConfirm].join(" ")} onClick={() => { claimData("/customer/property/commission/claim", (dataArr !== null ? (Number(dataArr.property_commission.claim_all) + Number(dataArr.property_commission.claim_day)) : 0)) }}>
                            รับค่าคอม
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles.boxMarginFormTop}>
                <div className={classes.boxCondition}>
                    <div className={classes.contentText} style={{ fontWeight: "400" }}>
                        <div className={classes.boxConditionTitle}>
                            *กติกาการรับค่าคอม*
                        </div>

                        {
                            dataArr !== null && (
                                ReactHtmlParser(dataArr.property_commission.condition)
                            )
                        }
                        {/* การถอน “ค่าคอม”
                        <br />
                        1. กดรับคืน “ค่าคอม” ได้ วันละ 1 ครั้ง เวลาไหนก็ได้
                        <br />
                        2. กรณีถอนเข้า “กระเป๋าเครดิต” จะต้องมียอดเสียที่ได้รับขั้นต่ำ 1 บาท และ มียอดในกระเป๋าเครดิตต่ำกว่า 10บาท ถึงจะถอนได้
                        <br />
                        3. กรณีถอนเป็น “เงินสด” จะต้องมียอดขั้นต่ำ 1 บาท ถึงจะถอนได้
                        <br /><br />
                        กฏกติกา “ค่าคอมมิชชั่น”
                        <br />
                        1. หากเลือกรับ “ค่าคอมมิชชั่น” แล้วจะไม่สามารถรับ “ยอดเสีย” ในวันเดียวกันได้อีก (เลือกรับได้อย่างใดอย่างหนึ่งในวันนั้นๆ)
                        <br />
                        2. เมื่อกดรับ “ค่าคอมมิชชั่น” แล้ว ระบบจะทำการ รีเซ็ต “ยอดเสีย” เป็น 0 ทันที
                        <br />
                        3. “ค่าคอมมิชชั่น” ที่ได้รับ ไม่ต้องทำยอดสามารถถอนได้ทันที
                        <br />
                        4. หากพบเห็นการเดิมพันที่ผิดปกติระบบจะทำการตัดเครดิตโดย
                        อัตโนมัติทันทีและไม่สามารถคืนเงินได้ */}
                    </div>
                </div>
            </div>
        </>
    );

    const claimData = (path, amount) => {
        if (dataArr !== null) {
            if (amount > 0) {
                dispatch(setStatusSpinner({status: true, textProcess: null}));
                claimAll(path, dataArr.property_type).then((data) => {
                    dispatch(setStatusSpinner({status: false, textProcess: null}));
                    const statusCode = data.code;
                    if (data.code === 0 || data.code === 200) {
                        reloadBalance();
                        snackbarJson = {
                            statusShow: true,
                            statusAlert: "success",
                            titleAlert: "แจ้งเตือน",
                            textAlert: "รับค่าคอมสำเร็จ !"
                        };

                        openBox(snackbarJson);
                        loadData();

                        if (path === "/customer/property/cashback/claim") {
                            gotoPage("cashback");
                        }
                        else {
                            gotoPage("commission");
                        }
                    }
                    else {
                        alertResponse(statusCode);
                    }
                });
            }
        }
    };

    const reloadBalance = () => {
        loadHomeData().then((data) => {
            const statusCode = data.code;
            if (data.code === 0 || data.code === 200) {
                dispatch(setHomeData(data.result));
            }
            else {
                alertResponse(statusCode);
            }
        });
    };

    const alertResponse = (statusCode) => {
        if (statusCode === 2 || statusCode === 4) {
            snackbarJson = alertText(statusCode);
            openBox(snackbarJson);

            setTimeout(function () {
                window.location.href = `/?web=${process.env.REACT_APP_DEFAULT_AGENT_CODE}`;
            }, 5000);
        }
        else {
            snackbarJson = alertText(statusCode);
            openBox(snackbarJson);
        }
    };

    const openBox = (snackbarJson) => {
        dispatch(setDataAlert({
            type: snackbarJson.statusAlert,
            title: snackbarJson.titleAlert,
            text: snackbarJson.textAlert,
            action: true,
            redirect: null,
            show: true,
            buttonText: "ตกลง"
        }));
    };

    useEffect(() => {
        if (dataArr !== null) {
            if (dataArr.property_cashback.percent <= 0 && dataArr.property_commission.percent > 0) {
                setValue(1);
                setHideMenuCom(true);
                setHideMenuCashback(true);
            }
            else if (dataArr.property_cashback.percent > 0 && dataArr.property_commission.percent <= 0) {
                setValue(0);
                setHideMenuCom(true);
                setHideMenuCashback(true);
            }
            else {
                setValue(0);
                setHideMenuCashback(false);
                setHideMenuCom(false);
            }
        }
    }, [dataArr]);

    return (
        <>
            <div className={modalStyles.boxTitle}>
                รับทรัพย์{dataArr !== null && (dataArr.property_name)}
            </div>

            <div className={styles.boxMarginTop}>
                <div className={classes.root}>
                    {
                        (hideMenuCashback === false && hideMenuCom === false) && (
                            <Tabs
                                variant="fullWidth"
                                value={value}
                                onChange={handleChange}
                                classes={{
                                    indicator: classes.indicator
                                }}
                            // indicatorColor="unset"
                            >
                                <LinkTab label="ยอดเสีย" className={value === 0 ? classes.tabTextActive : classes.tabText} {...a11yProps(0)} />
                                <LinkTab label="คอมมิชชั่น" className={value === 1 ? classes.tabTextActive : classes.tabText} {...a11yProps(1)} />
                            </Tabs>
                        )
                    }

                    <TabPanel value={value} index={0} className={classes.tabContent}>
                        {
                            renderContentCashback()
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1} className={classes.tabContent}>
                        {
                            renderContentCommission()
                        }
                    </TabPanel>
                </div>
            </div>
        </>
    );
}